// --------------------------------------- Project List
import sta from "../Assets/sta.png";
import tutoraap from "../Assets/tutoraap.png";
import Covitec from "../Assets/covitec.jpg";
import antena from "../Assets/antena.jpg";
import pelis from "../Assets/pelis.png";
import tiempo from "../Assets/tiempo.png";
import face from "../Assets/face.png";
export const ProjectList = [
  {
    img: `${sta}`,
    title: "STA.V2",
    description: "Sitio del Sistema de Trayectoria Académica del ITSCH.",
    tech_stack: "Html, Css, Javascript, Laravel, MySQL",
    github_url: "",
    demo_url: "",
  },
  {
    img: `${tutoraap}`,
    title: "TutorApp",
    description: "Web para asesorías entre alumnos.",
    tech_stack: "Html, Css, Javascript, Laravel, MySQL",
    github_url: "",
    demo_url: "",
  },
  {
    img: `${Covitec}`,
    title: "Covitec",
    description: "Control de acceso al tecnológico.",
    tech_stack: "Flutter, Dart, MySQL",
    github_url: "",
    demo_url: "",
  },
  {
    img: `${antena}`,
    title: "TuAntenApp",
    description:
      "Aplicación para calcular parámetros de señales de las antenas y para la elección de antenas.",
    tech_stack: "Python",
    github_url: "",
    demo_url: "",
  },
  {
    img: `${pelis}`,
    title: "Películas",
    description: "Web para consultar películas.",
    tech_stack: "Html, Css, React",
    github_url: "https://github.com/DrackDMG/peliculas",
    demo_url: "https://drackdmg.github.io/peliculas/#/",
  },
  {
    img: `${tiempo}`,
    title: "Clima",
    description: "Web para ver el clima.",
    tech_stack: "Html, Css, React",
    github_url: "https://github.com/DrackDMG/clima",
    demo_url: "https://drackdmg.github.io/clima",
  },
  {
    img: `${face}`,
    title: "Clone Facebook",
    description: "Clon de Facebook con Angular.",
    tech_stack:
      "Html, Css, Angular, Firebase, Bootstrap, TypeScript, Angular Material",
    github_url: "",
    demo_url: "https://face-angular-course.web.app/login",
  },
];

// --------------------------------------- //

export const stackList = [
  {
    img: "https://www.vectorlogo.zone/logos/w3_html5/w3_html5-icon.svg",
    name: "HTML",
    niv: "Intermedio",
  },
  {
    img: "	https://www.vectorlogo.zone/logos/w3_css/w3_css-icon.svg",
    name: "CSS",
    niv: "Intermedio",
  },
  {
    img: "https://www.vectorlogo.zone/logos/getbootstrap/getbootstrap-icon.svg",
    name: "Bootstrap",
    niv: "Intermedio",
  },
  {
    img: "https://www.vectorlogo.zone/logos/tailwindcss/tailwindcss-icon.svg",
    name: "TailwindCSS",
    niv: "Intermedio",
  },
  {
    img:
      "https://raw.githubusercontent.com/gurupawar/website/main/src/Assets/skill/responsive.svg",
    name: "Responsive",
    niv: "Intermedio",
  },
  {
    img: "https://www.vectorlogo.zone/logos/javascript/javascript-icon.svg",
    name: "JavaScript",
    niv: "Intermedio",
  },
  {
    img: "https://www.vectorlogo.zone/logos/reactjs/reactjs-icon.svg",
    name: "ReactJS",
    niv: "Básico",
  },
  {
    img: "https://www.vectorlogo.zone/logos/angular/angular-icon.svg",
    name: "Angular",
    niv: "Básico",
  },
  // {
  //   img: "	https://cdn.worldvectorlogo.com/logos/next-js.svg",
  //   name: "NextJS",
  //   niv: "Básico",
  // },

  // {
  //   img: "	https://upload.wikimedia.org/wikipedia/commons/thumb/1/1b/Svelte_Logo.svg/1702px-Svelte_Logo.svg.png",
  //   name: "Svelte",
  //   niv: "Básico",
  // },
];

export const stackListB = [
  {
    img: "https://www.vectorlogo.zone/logos/springio/springio-icon.svg",
    name: "Spring Boot",
    niv: "Básico",
  },
  {
    img: "https://www.vectorlogo.zone/logos/mysql/mysql-icon.svg",
    name: "MySQL",
    niv: "Intermedio",
  },
  {
    img:
      "https://uxwing.com/wp-content/themes/uxwing/download/business-professional-services/microservice-icon.png",
    name: "Microservicios",
    niv: "Básico",
  },
  {
    img: "https://www.vectorlogo.zone/logos/java/java-icon.svg",
    name: "Java",
    niv: "Intermedio",
  },
  {
    img: "https://www.vectorlogo.zone/logos/php/php-icon.svg",
    name: "PHP",
    niv: "Intermedio",
  },
  {
    img: "https://cdn.worldvectorlogo.com/logos/laravel-2.svg",
    name: "Laravel",
    niv: "Intermedio",
  },

  {
    img: "https://cdn.worldvectorlogo.com/logos/nodejs-icon.svg",
    name: "NodeJS",
    niv: "Básico",
  },
  {
    img: "https://www.vectorlogo.zone/logos/mongodb/mongodb-icon.svg",
    name: "MongoDB",
    niv: "Básico",
  },
];
export const stackListO = [
  {
    img: "https://www.vectorlogo.zone/logos/python/python-icon.svg",
    name: "Python",
    niv: "Intermedio",
  },

  {
    img: "	https://www.vectorlogo.zone/logos/amazon_aws/amazon_aws-icon.svg",
    name: "AWS",
    niv: "Intermedio",
  },

  {
    img: "https://www.vectorlogo.zone/logos/dartlang/dartlang-icon.svg",
    name: "Dart",
    niv: "Básico",
  },
  {
    img:
      "https://www.vectorlogo.zone/logos/typescriptlang/typescriptlang-icon.svg",
    name: "TypeScrip",
    niv: "Básico",
  },

  {
    img: "https://www.vectorlogo.zone/logos/flutterio/flutterio-icon.svg",
    name: "Flutter",
    niv: "Básico",
  },

  // {
  //   img: "	https://www.vectorlogo.zone/logos/kotlinlang/kotlinlang-icon.svg",
  //   name: "Kotlin",
  //   niv: "Basico",
  // },
  {
    img: "https://www.vectorlogo.zone/logos/git-scm/git-scm-icon.svg",
    name: "Git",
    niv: "Básico",
  },

  {
    img: "https://www.vectorlogo.zone/logos/github/github-icon.svg",
    name: "GitHub",
    niv: "Básico",
  },

  {
    img: "https://getcomposer.org/img/logo-composer-transparent.png",
    name: "Composer",
    niv: "Básico",
  },
  {
    img: "https://www.vectorlogo.zone/logos/yarnpkg/yarnpkg-icon.svg",
    name: "Yarn",
    niv: "Básico",
  },
  {
    img: "	https://www.vectorlogo.zone/logos/npmjs/npmjs-icon.svg",
    name: "npm",
    niv: "Básico",
  },
  {
    img: "https://avatars.githubusercontent.com/u/45949248?s=200&v=4",
    name: "Lombok",
    niv: "Básico",
  },
];
