import React from "react";
import styled from "styled-components";

const FooterSection = styled.div`
  span {
    position: absolute;
    bottom: 3rem;
    color: #fff;

  }
`;
function Footer() {
  return (
    <FooterSection>
      <div className="bg">
        <div className="Container">
          <span>
            Creado por DanielMG
          </span>
        </div>
      </div>
    </FooterSection>
  );
}

export default Footer;
