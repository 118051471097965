import React from "react";
import { stackList } from "../../data/ProjectData";
import { stackListB } from "../../data/ProjectData";
import { stackListO } from "../../data/ProjectData";
import yo1 from "../../Assets/yo1111.png";

import {
  Image,
  Technologies,
  Tech,
  TechImg,
  TechName,
  TechNiv,
  ContactWrapper,
} from "./AboutElements";
function About() {
  return (
    <ContactWrapper id="about">
      <div className="Container">
        <div className="SectionTitle">Sobre mí</div>
        <div className="BigCard bx">
          <Image src={yo1} alt="DrackMG" />
          <div className="AboutBio">
            ¡Hola! Mi nombre es <b>Daniel Morales</b> Ingeniero en Tecnologías
            de la Información y Comunicaciones. Creativo, empático,
            comprometido, sé trabajar en equipo, me interesa el área de
            desarrollo de software, desarrollo web y desarrollo móvil; soy
            apasionado por lo que me gusta, estoy en constante crecimiento y
            aprendizaje.
          </div>
          <div className="AboutBio tagline2">
            Tengo experiencia usando las siguientes tecnologías.
          </div>
          <div className="AboutBio tagline23">Frontend</div>
          <Technologies>
            {stackList.map((stack, index) => (
              <Tech key={index} className="tech">
                <TechImg src={stack.img} alt={stack.name} />
                <TechName>{stack.name}</TechName>
                <TechNiv>{stack.niv}</TechNiv>
              </Tech>
            ))}
          </Technologies>
          <div className="AboutBio tagline23">Backend</div>
          <Technologies>
            {stackListB.map((stack, index) => (
              <Tech key={index} className="tech">
                <TechImg src={stack.img} alt={stack.name} />
                <TechName>{stack.name}</TechName>
                <TechNiv>{stack.niv}</TechNiv>
              </Tech>
            ))}
          </Technologies>
          <div className="AboutBio tagline23">Otras</div>
          <Technologies>
            {stackListO.map((stack, index) => (
              <Tech key={index} className="tech">
                <TechImg src={stack.img} alt={stack.name} />
                <TechName>{stack.name}</TechName>
                <TechNiv>{stack.niv}</TechNiv>
              </Tech>
            ))}
          </Technologies>
        </div>
      </div>
    </ContactWrapper>
  );
}

export default About;
