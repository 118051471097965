import React from "react";
import { ProjectList } from "../../../data/ProjectData";
import {
  Card,
  CardLeft,
  CardRight,
  Stack,
  BtnGroup,
} from "./ProjectCardElements";
function ProjectCard() {

  return (
    <>
      {ProjectList.map((list, index) => (
        <Card key={index}>
          <CardLeft>
            <img src={list.img} alt={list.name} />
          </CardLeft>
          <CardRight>
            <h4>{list.title}</h4>
            <p>{list.description}</p>
            <Stack>
              <span className="stackTitle">Tecnologias usadas -</span>
              <span className="tags">{list.tech_stack}</span>
            </Stack>
            {list.demo_url || list.github_url ?
              <BtnGroup>
                {list.github_url ? <a
                  className="btn btn2 SecondarBtn"
                  href={list.github_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Github
                </a> : ''}
                {list.demo_url ? <a
                  className="btn PrimaryBtn"
                  href={list.demo_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Demo ➜
                </a> : ''}
              </BtnGroup> : ''}
          </CardRight>
        </Card>
      ))}
    </>
  );
}

export default ProjectCard;
