import React from "react";
import { FaLinkedin, FaInstagram, FaGithub } from "react-icons/fa";
import CV from "../../Assets/CV Daniel Morales Gonzalez.pdf";
import styled from "styled-components";

export const SocialDiv = styled.div`
  margin-top: 2rem;
  display: none;
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
  }
  .item2 {
    font-size: 2.8rem;
    font-weight: 600;
  }

  a {
    font-size: 2.5rem;
    color: #151418;
    transition: 0.2s ease-in;
    &:hover {
      color: rgb(57, 134, 250);
    }
  }
  .item {
    display: flex;
    align-items: end;
    height: 65px;
  }

  .item + .item {
    margin-left: 2rem;
  }

  @media screen and (max-width: 768px) {
    display: block;
  }
`;

function SocialIcon() {
  return (
    <SocialDiv>
      <ul>
        <li className="item">
          <a
            href="https://www.linkedin.com/in/daniel-m-506abb129"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin />
          </a>
        </li>
        <li className="item">
          <a
            href="https://github.com/DrackDMG/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaGithub />
          </a>
        </li>
        <li className="item">
          <a
            href="https://www.instagram.com/drack001/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram />
          </a>
        </li>
        <li className="item">
          <a href={CV} download target="_blank" rel="noopener noreferrer">
            <p className="item2">CV</p>
          </a>
        </li>
      </ul>
    </SocialDiv>
  );
}

export default SocialIcon;
